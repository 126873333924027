﻿#entryExtensionForm {
    padding: 25px 0px
}

    #entryExtensionForm h4 {
        padding-bottom: 20px;
    }

    #entryExtensionForm #visitLink {
        padding-bottom: 20px;
    }

.extension-form-space {
    padding-bottom: 50px;
}

.green-line {
    content: '';
    width: 0;
    height: 100%;
    position: absolute;
    border-left: 3px solid #ec971f;
    top: 0;
    right: 0;
}

#statistics {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 75px;
    padding-bottom: 75px;
    text-align: center;
}

    #statistics h4 {
        padding-bottom: 10px;
    }

        #statistics h4 span {
            background: #ec971f;
            color: white;
            padding: 3px;
            border-radius: 3px;
        }

    #statistics .stat-item {
        text-align: left;
        background: #f6f5f5;
        border-radius: 3px;
        padding: 15px;
        margin-bottom: 5px;
        border: 1px solid #dbdada;
    }

        #statistics .stat-item span {
            color: #ec971f;
        }

        #statistics .stat-item strong {
            font-size: 14px;
            float: right;
        }
