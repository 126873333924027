﻿.navbar {
    border: 1px solid transparent;
    border-color: #e7e7e7;
    background-color: #f8f8f8;
}

    .navbar li {
        padding: 7px;
        font-size: 15px;
    }

        .navbar li:hover, .navbar li form:hover {
            background-color: #e7e7e7 !important;
        }

#facebook a:hover {
    background-color: transparent !important;
}

nav {
    padding: 0px !important;
    margin-bottom: 50px !important;
}

    nav .container {
        padding-right: 0px !important;
    }

    nav button {
        margin-left: 5px;
    }

.navbar-form {
    padding-right: 0px !important;
}

.navbar .navbar-center h3 {
    margin-top: 10px !important;
}

.navbar .container, .navbar .container .navbar-collapse {
    padding-left: 0px !important;
}

.navbar li form button {
    background: transparent !important;
    border: transparent;
    color: #777;
}

.navbar li form {
    line-height: 20px;
    position: relative;
    display: block;
    padding: 0px 15px;
    margin-bottom: 0px !important;
    padding-top: 10px;
    padding-bottom: 15px;
}

.navbar .glyphicon {
    top: 0px !important;
}

.submit-entry-button {
    padding: 6px 12px !important;
}

.btn-default {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
}