﻿#acceptEntryForm #Description {
    min-height: 100px;
}

.entry-url-column {
    width: 300px !important;
} 

.isissued-column {
    width: 75px !important;
} 

.added-column {
    width: 150px !important;
}

.company-column {
    width: 300px;
}
