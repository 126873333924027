﻿.process-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

    .process-info .step-number {
        font-size: 5rem;
        font-weight: bolder;
        color: #ededed;
        margin-right: 15px;
    }

    .process-info .details {
        text-align: left;
    }

    .process-info .motto {
        font-size: 1.3rem;
        font-weight: 700;
        color: #444f60;
    }

    .process-info .description {
        color: #999;
    }
