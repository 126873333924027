﻿:root {
    --light-orange: #f1b980;
    --orange: #f0ad4e;
    --green: #4a7c7c;
    --light-green: #5b9b9a;
}

body {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    line-height: 1.42857143;
    font-size: 14px;
    color: #333;
}

.input-group * {
    font-size: 14px;
    height: 34px !important;
}

.list-group-item {
    padding: 10px 15px;
}

.navbar {
    padding: .4rem 1rem;
}

.btn-warning {
    background-color: var(--light-green) !important;
    border-color: var(--light-green) !important;
    color: white;
}

    .btn-warning:hover, .btn-warning:active, .btn-warning:focus {
        color: white !important;
    }

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn.focus:active,
.btn:active:focus,
.btn:focus {
    outline: 0 !important;
    outline-offset: 0 !important;
    background-image: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.tooltip {
    font-size: 13px;
}

.datepicker.dropdown-menu {
    width: 250px;
}

a, a:active, a:hover, a:focus {
    color: black;
}

.form-control:focus {
    border-color: var(--light-green);
    box-shadow: 0 1px 1px var(--light-green) inset, 0 0 8px var(--light-green);
    outline: 0 none;
}

[readonly] {
    border-color: transparent !important;
    box-shadow: none !important;
    outline: 0 none;
}

.icon-middle-size {
    font-size: 17px;
}

.icon-large-size {
    font-size: 20px;
}

.text-highlight {
    color: var(--light-green);
}

.text-white {
    color: white;
}

.pointer-cursor {
    cursor: pointer;
}

.navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show > .nav-link {
    color: #555;
}

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus,
.active {
    color: #555;
}

.btn {
    font-size: 14px !important;
    border-radius: 4px !important;
}

.form-control {
    font-size: 14px !important;
}

.form-group {
    margin-bottom: 14px;
}

.highlight {
    background-color: var(--light-orange);
    padding: 0px 5px;
    vertical-align: middle;
    display: inline-block;
    color: white;
    border-radius: 3px;
}

.btn-warning.disabled, .btn-warning:disabled {
    background: var(--light-green) !important;
    border-color: var(--light-green) !important;
    color: white !important;
    opacity: 0.8;
}

a:hover {
    text-decoration: none;
}

.pagination a {
    color: rgba(0,0,0,.8) !important;
    text-align: center;
}

.pagination .active a {
    color: white !important;
}

.pagination li[disabled] {
    opacity: 0.6;
}

.page-item {
    margin-right: 10px;
}

    .page-item.active .page-link {
        background-color: var(--light-green);
        border-color: var(--light-green);
    }

    .page-item:first-child .page-link {
        border-top-left-radius: .15rem;
        border-bottom-left-radius: .15rem;
    }

    .page-item:last-child .page-link {
        border-top-right-radius: .15rem;
        border-bottom-right-radius: .15rem;
    }

.dots-container {
    display: table;
    height: 35px;
}

.dots {
    display: table-cell;
    vertical-align: middle;
}

a.active.focus,
a.active:focus,
a.focus,
a.focus:active,
a:active:focus,
a:focus {
    outline: 0 !important;
    outline-offset: 0 !important;
    background-image: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.h6 {
    font-size: 1.15rem;
} 