﻿#summary {
    padding-top: 5px;
}

    #summary .headline {
        margin-right: 0px !important;
    }

    #summary h1 {
        padding-bottom: 20px;
    }

    #summary .jumbotron {
        padding: 3rem 2rem 1rem 2rem !important;
    }
