﻿#status {
    padding-bottom: 200px;
}

    #status h3 {
        padding-bottom: 30px;
    }

    #status .list-group-item:hover, #status .list-group-item:active {
        background: none;
    }

    #status .list-group-item:nth-child(2n) {
        background: #f4f4f4;
    }
