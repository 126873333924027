﻿@media (min-width: 992px) {
    #recentlyAdded > *, #relatedEntries > * {
        padding-left: 0px !important;
    }

    #recentlyAdded > div:last-child{
        padding-right: 0px !important;
    } 

    .new-entry-well {
        border-right: 1px dashed lightgrey;
    }

    .new-entry-terms {
        border: 1px dashed lightgrey;
    }

    .navbar-center {
        position: absolute;
        left: 50%;
        transform: translatex(-50%);
    }

    #adminNavbar .navbar-center * {
        font-size: 23px;
    }

    .parameter {
        display: inline-block;
        margin-top: 15px;
        padding-left: 15px;
    }  
}

@media(min-width:768px) {
    #footer {
        padding-top: 25px;
    }

    #homePage .jumbotron > hr {
        margin-left: 100px;
        margin-right: 100px;
    }

    .login-dialog .modal-dialog {
        max-width: initial !important;
        width: 625px !important;
    }

    #homePage {
        margin-top: -10px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    #statistics {
        padding-bottom: 35px !important;
    }

    .extension-form-space {
        padding-bottom: 0px !important;
    }

    #relatedEntries > * {
        padding-left: 0px !important;
    }

    #relatedEntries > div:last-child {
        padding-right: 0px !important;
    }

    #relatedEntries {
        margin-left: 0px;
        margin-right: 0px;
    }

    .navbar-nav.navbar-center {
        float: left;
    }

    .parameter {
        display: inline-block;
        margin-top: 15px;
    }

    nav {
        margin-bottom: 20px !important;
    }
}

@media (max-width: 767px) {
    #footer {
        padding-top: 10px;
    }

    #statistics {
        padding-top: 35px !important;
        padding-bottom: 35px !important;
    }

    .extension-form-space {
        padding-bottom: 0px !important;
    }  

    .navbar-nav.navbar-center li {
        display: inline-block;
        float: none;
    }

    .parameter {
        display: inline-block;
        margin-top: 15px;
    }

    #searchForm input {
        text-align: center;
        margin-left: 20%;
        width: 50%;
        display: inline-block;
    }

    #searchForm button {
        width: 15%;
    }

    .coronavirus-message .coronavirus-headline {
        margin-bottom: 10px !important;
    }
}

@media (max-width: 480px) {
    #statistics {
        padding-top: 35px !important;
        padding-bottom: 35px !important;
    }

    .extension-form-space {
        padding-bottom: 0px !important;
    }

    .parameter {
        margin-top: 15px;
    }

    #searchForm input {
        width: 65%;
        margin-left: 0%;
        text-align: center;
        display: inline-block;
    } 

    #searchForm button {
        width: 30%;
    }

    #searchForm input {
        text-align: center;
        display: inline-block;
    }

    nav {
        margin-bottom: 20px !important;
    }
}
