﻿.entry-search-result {
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding: 15px 0px 0px 0px;
    display: table;
    width: 100%;
    border-bottom: 1px solid #dcdcdc;
}

.well .entry-search-result:last-child {
    border-bottom: 1px solid transparent;
}

.entry-search-result div {
    display: table-cell;
    vertical-align: middle;
}

.entry-search-result:last-child {
    margin-bottom: 0px;
}

.entry-search-result .title {
    font-size: initial;
    margin-top: 0;
    margin-bottom: 0;  
    color: initial;
}

.no-hortizontal-padding {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}  