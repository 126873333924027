﻿.related-entry {
    height: 210px;
}  

.related-entry-description { 
    height: 83px;
} 

.entry-description {
    white-space: pre-line;
    padding-bottom: 10px;
}

@media (max-width: 767px) {  
    .related-entry-description {
        height: 50%;
    }
} 

.non-clickable-entry-detail {
    background-color: var(--light-green);
    padding: 0px 5px;
    vertical-align: middle;
    display: inline-block;
    color: white;
    border-radius: 3px;
    margin-right: 5px;
}

.clickable-entry-detail {
    background-color: var(--light-orange);
    padding: 0px 5px;
    vertical-align: middle;
    display: inline-block;
    color: white;
    border-radius: 3px;
    margin-right: 5px;
}