﻿table input[type="text"] {
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}

table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
}

th, td {
    padding: 8px !important;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

td {
    word-break: break-all !important;
    white-space: nowrap;
}

tbody tr:hover {
    background-color: #f5f5f5;
}

.icon-column {
    width: 25px;
}

.id-column {
    width: 80px;
}

.subscription-column {
    width: 200px;
}
