﻿#newEntryForm textarea[name='Description'] {
    min-height: 115px;
}

#newEntryForm input[type='text'], #newEntryForm #Tags, #newEntryForm select {
    height: 40px;
}

.no-navbar-top-margin {
    margin-top: -50px;
}

.control-label-details {
    padding-top: 3px;
    display: block;
    font-weight: normal !important;
    font-size: 11px;
}

.control-label-details-item {
    display: block;
}

.new-entry-container {
    background: #f8f9fa;
    border-radius: 1px 1px 5px 5px;
    border: 1px solid #e8e8e8;
} 

.faq {
    border-left: 1px dashed lightgrey;
}

.tag {
    margin-bottom: 5px;
    display: inline-block;
}

.modal-header {
    background: var(--light-green) !important;
}

.new-entry-modal-button {
    width: 150px;
}

.modal-footer {
    text-align: center !important;
}

#termsModal ol li {
    padding-bottom: 11px;
}

@media (min-width: 768px) {
    .col-form-label {
        text-align: right !important;
    }
}

@media (max-width: 767px) {
    .col-form-label {
        text-align: left;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

    .subscription-label {
        padding-bottom: 10px !important;
    }
}

.no-top-padding {
    padding-top: 0px !important;
}

#newEntryForm #submitSiteButton {
    width: 125px;
    padding: 5px 8px !important;
} 

#Tags {
    min-height: 50px;
}

.new-entry-terms h5 {
    font-size: 1.1rem !important;
}