﻿.headline {
    margin-top: -2px;
    margin-right: 13px;
    background-color: var(--light-orange);
    padding: 3px 7px;
    vertical-align: middle;
    display: inline-block;
    color: white;
    border-radius: 3px;
    font-size: 1.1rem;
}

.submit-site-button-container {
    border-radius: 5px;
    background: var(--light-green) !important;
    border: 1px solid #e7e7e7;
}

@media (min-width: 768px) {
    .submit-site-button-container .submission-terms {
        border-right: 1px dashed lightgrey;
    }
}

.submit-site-button-container ul li {
    list-style-type: none;
    text-align: left;
}

    .submit-site-button-container ul li span {
        color: #ededed;
    }

.submit-site-button-container a {
    padding: 10px 30px !important;
}

hr {
    border-top: 1px dashed rgba(0, 0, 0, .1) !important;
}

.categories {
    background: transparent !important;
}

@media (max-width: 767px) {
    .jumbotron {
        padding: 0 !important;
    }

    .my-4 {
        margin-bottom: 1rem;
    }
}

.horizontal-list {
    padding-left: 0;
    margin-left: 0;
}

    .horizontal-list li {
        float: left;
        list-style-type: none;
        margin-right: 15px;
    }

#titleContainer {
    padding-bottom: 50px;
}

    #titleContainer h2 {
        font-style: italic;
        font-family: Georgia, serif;
        padding-bottom: 15px;
    }

.well {
    min-height: 20px;
    padding: 19px 19px 19px 19px;
    margin-bottom: 20px;
    background-color: #f8f9fa;
    border-radius: 5px;
    border: 1px solid #e8e8e8;
} 

.lead {
    font-weight: 300;
    font-size: 2.5rem;
    margin-bottom: 7px;
}

#homePage .jumbotron {
    background-color: transparent;
    padding: 2rem;
    border-radius: 5px;
}

#footer {
    padding-bottom: 25px;
}

    #footer p {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        margin-bottom: 0px !important;
        margin-top: 0px !important;
    }

#homePage h4 {
    padding-top: 25px;
    padding-bottom: 15px;
}

.submit-new-entry-button {
    padding: 6px 12px !important;
    background: var(--orange) !important;
    border-color: var(--orange) !important;
}
